const nedTranslations = {
  // Navbar
  Categories: 'Categorieën',
  Products: 'Producten',
  Track: 'Track',
  NeedAssistance: 'Hulp nodig?',
  // Not Found
  ThePageYourLookingForDoesntExist: 'De pagina die u zoekt bestaat niet',
  BackHome: 'Terug naar home',
  Home: 'Home',
  // Checkout
  ShippingAddress: 'Verzendadres',
  Checkout: 'Afrekenen',
  ThankYouForYourOrder: 'Bedankt voor uw bestelling',
  YourOrderNumber: 'Uw bestelnummer',
  OrderConfirmation: 'We hebben je orderbevestiging per e-mail verzonden en sturen je een update wanneer je bestelling is verzonden.',
  Back: 'Terug',
  PaymentMethod: 'Betalingsmethode',
  OrderSummary: 'Bestelling samenvatting',
  Total: 'Totaal',
  PaymentDetails: 'Betalingsgegevens',
  OrderDate: 'Besteldatum',
  Name: 'Naam',
  Quantity: 'Aantal',
  Price: 'Prijs',
  Client: 'Klant',
  company: 'bedrijf',
  name: 'naam',
  email: 'email',
  // Category
  Category: 'Categorie',
  SportItems: 'Sportartikelen',
  CareItem: 'Verzorgingsartikelen',
  Hardware: 'Hardware',
  NoItemsFound: 'Geen artikelen gevonden',
  // Rest
  Address: 'Adres',
  AdditionalInformation: 'Extra informatie',
  MyWishlist: 'Mijn verlanglijst',
  Account: 'Account',
  Cart: 'Winkelwagen',
  Language: 'Taal',
  search: 'zoeken',
  Join: 'Aansluiten',
  Create: 'Aanmaken',
  Street: 'Straat',
  Number: 'Nummer',
  ZipCode: 'Postcode',
  City: 'Stad',
  Country: 'Land',
  Alias: 'Alias',
  FirstNam: 'Voornaam',
  LastName: 'Achternaam',
  Email: 'Email',
  Role: 'Rol',
  AddressLine1: 'straat',
  AddressLine2: 'straatnummer',
  StateProvinceRegion: 'Staat / Provincie / Regio',
  ZipPostalCode: 'Postcode',
  UseThisAddressForPaymentDetails: 'Gebruik dit adres voor betalingsgegevens',
  NameOnCard: 'Naam op kaart',
  CardNumber: 'Kaartnummer',
  ExpiryDate: 'Vervaldatum',
  RememberCreditCardDetailsForNextTime: 'Onthoud creditcardgegevens voor de volgende keer',
  LastThreeDigitsOnSignatureStrip: 'De laatste drie cijfers op de handtekeningstrip',
  ShareWithOthers: 'Delen met anderen',
  CopyThisLinkThenYouCanPasteAndShareIt: 'Kopieer deze link, dan kunt u het plakken en delen',
  Copy: 'Kopieer',
  ItIsCurrentlyNot: 'Het is momenteel niet mogelijk om uw bestelling te wijzigen nadat deze is geplaatst.',
  // Account
  UserInformation: 'Gebruikersinformatie',
  AddressInformation: 'Adresinformatie',
  Options: 'Opties',
  Logout: 'Uitloggen',
  DeleteAccount: 'Account verwijderen',
  LeaveCompany: 'Verlaat bedrijf',
  DeleteCompany: 'Bedrijf verwijderen',
  Save: 'Opslaan',
  AdminPanel: 'Admin panel',
  ChangeRole: 'Verander rol',
  ChangeTheRoleOfAnEmployeeOrAllowAnAccountToJoinTheCompany: 'Verander de rol van een werknemer of laat een account toe om deel te nemen aan het bedrijf',
  CompanyOptions: 'Bedrijfsinstellingen',
  EmployeePanel: 'Werknemer panel',
  Orders: 'Bestellingen',
  UseVATNumberToGetCompanyInformation: 'Gebruik BTW-nummer om bedrijfsinformatie te krijgen?',
  CreateCompany: 'Bedrijf aanmaken',
  Login: 'Inloggen',
  NoAccount: 'Geen account?',
  Register: 'Registreren',
  goToLogin: 'Ga naar inloggen',
  LoginRequired: 'Inloggen vereist',
  YouNeedToLoginToAccessThisPage: 'U moet inloggen om toegang te krijgen tot deze pagina',
  GoToLogin: 'Ga naar inloggen',
  AccountConfirmation: 'Weet je zeker dat je je account wilt verwijderen? Alle gebruikersgegevens verwijderen en uitloggen?',
  AdminDelete: 'Een beheerdersaccount kan alleen worden verwijderd als er een andere beheerder in het bedrijf is!',
  FailedDelete: 'Kan account niet verwijderen; benoem eerst een nieuwe beheerder',
  FailedDelete2: 'Kan account niet verwijderen',
  DeleteSuccess: 'Account succesvol verwijderd',
  LEAVECOMPANY: 'VERLAAT BEDRIJF',
  FailedLeave: 'Kan het bedrijf niet verlaten; benoem eerst een nieuwe beheerder',
  FailedLeave2: 'Kan het bedrijf niet verlaten',
  SuccessLeave: 'Succesvol het bedrijf verlaten',
  DeleteCompConfirmation: 'Weet u zeker dat u uw bedrijf wilt verwijderen?',
  DELETECOMPANY: 'VERWIJDER BEDRIJF',
  FailedDeleteComp: 'Kan bedrijf niet verwijderen',
  SuccessDeleteComp: 'Bedrijf succesvol verwijderd',
  CanDelete: 'U kunt uw account verwijderen',
  CanLeave: 'U kunt uw bedrijf verlaten',
  CanDeleteComp: 'U kunt uw bedrijf verwijderen',
  UserInfSaved: 'Gebruikersinformatie opgeslagen',
  success: 'Succes',
  AddressInfoSaved: 'Adresinformatie opgeslagen',
  RoleOrStatus: 'De rol of status van werknemers binnen het bedrijf',
  RoleConfirmation: 'Weet je zeker dat je je eigen rol wilt wijzigen?',
  CHANGEROLE: 'VERANDER ROL',
  RoleChangeFailed: 'Kan uw eigen rol niet wijzigen, zorg ervoor dat er ten minste één andere beheerder is!',
  FirstEnterEmail: 'Voer eerst een e-mailadres in van het account dat u wilt promoten',
  SelectMergeStrat: 'Selecteer een samenvoegstrategie',
  FailedCreateComp: 'Kan bedrijf niet aanmaken',
  SuccesfullyRequested: 'Succesvol aangevraagd om een bedrijf te joinen',
  FailedJoinComp: 'Kan bedrijf niet joinen',
  JOINCOMPANY: 'JOIN BEDRIJF',
  PutOnWaitingList: 'Je werd op een wachtlijst geplaatst om je aan te sluiten bij een bedrijf. De beheerder van dat bedrijf zal je eerst moeten valideren.',
  CHANGEREQUEST: 'VERANDER VERZOEK',
  CompanyDontExist: 'Bedrijf bestaat niet; zorg ervoor dat je het formatteert als BE0123456789',
  VATNumber: 'BTW-nummer',
  CompanyName: 'Bedrijfsnaam',
  FailedToRegister: 'Kan niet registreren; Vul een geldig e-mailadres in',
  FailedToLogin: 'Inloggen mislukt; e-mail of wachtwoord verkeerd',
  FailedToLogin2: 'Inloggen mislukt; vul verplichte velden in',
  SignIn: 'Aanmelden',
  Password: 'Wachtwoord',
  FailedToRegister2: 'Kan niet registreren; Gebruiker bestaat al',
  FailedToRegister3: 'Registreren mislukt',
  FailedToRegister4: 'Kan niet registreren; vul alle invoervelden in',
  Username: 'Gebruikersnaam',
  // Banner
  Interested: 'Geïnteresseerd?',
  ShipWithConfidence: 'Verzend met vertrouwen: Hassle-Free Shipping Solutions',
  BannerDescription: 'Lever met vertrouwen! Ontvang probleemloze verzending met onze vertrouwde services. Kies uit een verscheidenheid aan opties om binnenlands of internationaal te verzenden, en u kunt er zeker van zijn dat uw pakketten veilig en op tijd aankomen.',
  // Cart
  MyShoppingCart: 'Mijn winkelwagen',
  NoItemsInCart: 'Geen items in winkelwagen',
  ThisIsSomeRandomInfo: 'Dit is wat willekeurige info',
  Subtotal: 'Subtotaal',
  TAX: 'BTW',
  Shipping: 'Verzending',
  NetPrice: 'Nettoprijs',
  OrderNow: 'Bestel nu',
  ClearCart: 'Winkelwagen legen',
  // Categories
  FailedToLoadCategories: 'Kan categorieën niet laden',
  NoCategoriesFound: 'Geen categorieën gevonden',
  // Contact
  DoYouHaveAQuestionOrDoYouWantMoreInformation: 'Heb je een vraag of wil je meer informatie?',
  IamNotACustomerYet: 'Ik ben nog geen klant',
  WouldYouLikeMoreInformationAboutOurOfferingsOrServices: 'Wilt u meer informatie over onze aanbiedingen of diensten?',
  FillOutTheContactFormAndWeWillGetBackToYou: 'Vul het contactformulier in en we nemen zo snel mogelijk contact met u op.',
  FillOutAContactForm: 'Vul een contactformulier in',
  IAmACustomer: 'Ik ben een klant',
  DoYouHaveASpecificQuestionOrSuggestions: 'Heb je een specifieke vraag of suggesties?',
  LetUsKnow: 'Laat het ons weten en we beantwoorden je vraag zo snel mogelijk.',
  PerhapsYourQuestionIsAlreadyKnown: 'Misschien is uw vraag al bekend',
  PleaseReferToOur: 'Raadpleeg onze',
  FrequentlyAskedQuestions: 'Veelgestelde vragen',
  QuestionsAnswers: 'Vragen & antwoorden',
  WhyCantICompleteMyOrder: 'Waarom kan ik mijn bestelling niet voltooien?',
  CheckTheFollowingItems: 'Controleer de volgende items:',
  AreYouLoggedInRegisterFirst: '1. Ben je ingelogd? Als u nog geen login heeft, moet u zich eerst registreren.',
  HaveYouAlreadyConfirmed: '2. Heb je de voorgestelde leverdatum al bevestigd of een andere datum gekozen? Deze datum ziet u bovenaan de overzichtspagina van uw winkelwagen.',
  AreThereProductsInYourShoppingBasket: '3. Zijn er producten in uw winkelmandje waarvan wordt aangegeven dat er geen of onvoldoende voorraad is? Pas indien nodig uw bestelhoeveelheden aan.',
  HowCanIChangeMyOrder: 'Hoe kan ik mijn bestelling wijzigen?',
  HowAndWhenDoIPay: 'Hoe en wanneer betaal ik?',
  WithAPositiveCreditScore: 'Bij een positieve credit score betaal je per factuur. Op basis van deze score worden ook de betalingstermijn en kredietlimiet bepaald. Anders betaal je bij levering aan onze bezorger via Bancontact, Visa of Mastercard.',
  ContactUs: 'Neem contact met ons op',
  FillUpTheForm: 'Vul het formulier in en ons team neemt binnen 24 uur contact met u op.',
  Submit: 'Verzenden',
  Information: 'Informatie',
  Call: 'Bel + 32 485 88 77 11',
  Mail: 'Mail naar qwertic@qwict.com',
  Phone: 'Telefoonnummer',
  TypeUrMessageHere: 'Typ hier uw bericht',
  // Footer
  FooterCap: `Welkom op onze verzendwebsite! We zijn een team van logistieke professionals die zich toeleggen op het bieden van efficiënte, betrouwbare en betaalbare verzendoplossingen voor particulieren en bedrijven. Met een netwerk van vertrouwde vervoerders en geavanceerde technologie maken we verzending tot een naadloze en probleemloze ervaring. Laat ons u helpen uw bereik uit te breiden met onze opties voor lucht-, zee- en grondtransport, evenals oplossingen voor inklaring en opslag. Neem vandaag nog contact met ons op voor meer informatie over hoe we u kunnen helpen met uw verzendbehoeften.`,
  AboutUs: 'Over ons',
  information: 'Informatie',
  OrderTracking: 'Bestelling volgen',
  PrivacyPolicy: 'Privacy & Beleid',
  TermsConditions: 'Terms & Conditions',
  myaccount: 'Mijn account',
  MyCart: 'Mijn winkelwagen',
  MyAccount: 'Mijn account',
  // Home
  WelcomeToDelaware: 'Welkom bij Delaware Shipping, uw vertrouwde partner voor al uw verzendbehoeften!',
  DelawareDescription: 'Bij Delaware Shipping begrijpen we dat verzending een ontmoedigende taak kan zijn. Of u nu een klein bedrijf bent dat producten naar klanten in het hele land wil sturen of een particulier die een geschenk naar een dierbare stuurt, wij zijn er om u te helpen. Onze missie is om onze klanten de hoogste kwaliteit verzenddiensten te bieden tegen concurrerende prijzen, terwijl we zorgen voor tijdige levering en uitstekende klantenservice.',
  mainimagedescription: 'hoofd foto beschrijving',
  continuereading: 'verder lezen...',
  FailedLoadCategories: 'Kan categorieën niet laden',
  // Notifications
  Notifications: 'Notificaties',
  NoNotifications: 'Geen notificaties',
  OrderId: 'Order Id',
  Date: 'Datum',
  Text: 'Tekst',
  Status: 'Status',
  audience: 'publiek',
  Audience: 'Publiek',
  subject: 'onderwerp',
  Subject: 'Onderwerp',
  date: 'datum',
  readBy: 'gelezen door',
  ReadBy: 'Gelezen door',
  action: 'actie',
  Action: 'Actie',
  General: 'Algemeen',
  FindAllNotificationsHere: 'Hier vind je al je meldingen. U kunt ze ook als gelezen markeren of archiveren.',
  AllInfoAboutOrders: 'Alle informatie over uw bestellingen vindt u hier.',
  ReadAndArchived: 'Gelezen en gearchiveerd',
  Read: 'Gelezen',
  FindReadNotificationsHere: 'U kunt hier alle gelezen meldingen vinden.',
  Archived: 'Gearchiveerd',
  FindArchivedNotificationsHere: 'U kunt hier alle gearchiveerde meldingen vinden.',
  // Order
  DeliveryAddress: 'Leveringsadres',
  Transportation: 'Transport',
  packaging: 'verpakking',
  PackagingType: 'Verpakkingssoort',
  TransportService: 'Transportdienst',
  VerificationCode: 'Verificatiecode',
  TrackYourPackage: 'Volg uw pakket',
  Details: 'Details',
  NoOrders: 'Geen bestellingen',
  ClientName: 'Naam van de klant',
  SuccesfullySavedAddress: 'Succesvol opgeslagen adresgegevens',
  SuccesfullyReceivedAddress: 'Succesvol ontvangen adresgegevens',
  SuccesfullyPlacedOrder: 'Succesvol bestelling geplaatst',
  PlaceOrder: 'Plaats bestelling',
  OrderPlaceConfirmation: 'Weet je zeker dat je deze bestelling wilt plaatsen?',
  PLACEORDER: 'PLAATS BESTELLING',
  FailedToPlaceOrder: 'Kon bestelling niet plaatsen',
  PackagingMethod: 'Verpakkingsmethode',
  SuccesfullySavedPaymentOption: 'Succesvol opgeslagen betaaloptie',
  CardType: 'Kaarttype',
  CardHolder: 'Kaarthouder',
  MrMs: 'Meneer / Mevrouw',
  // Orders
  OrderDetails: 'Bestelgegevens',
  geplaatst: 'geplaatst',
  inVerwerking: 'in verwerking',
  // Product
  ProductTitle: 'Producttitel',
  Currently: 'Momenteel',
  InStock: 'Op voorraad',
  AddToCart: 'Voeg toe aan winkelwagen',
  AddToWishlist: 'Voeg toe aan verlanglijst',
  HowManyAddToCart: 'Hoeveel wil je toevoegen aan winkelwagen?',
  AddedToCart: 'Toegevoegd aan winkelwagen',
  NotEnoughStock: 'Niet genoeg voorraad',
  AvailableCategories: 'Beschikbare categorieën',
  ShareThisProduct: 'Deel dit product',
  CopyThisLinkPasteShare: 'Kopieer deze link en plak hem in een bericht om te delen',
  // Track
  TrackOrder: 'Bestelling volgen',
};
export default nedTranslations;