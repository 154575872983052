const frTranslations = {
  // Navbar
  Categories: 'Catégories',
  Products: 'les produits',
  Track: 'Piste',
  NeedAssistance: 'Besoin d aide',
  // Not Found
  ThePageYourLookingForDoesntExist: 'La page que vous recherchez n existe pas',
  BackHome: `Retour à l'accueil'`,
  Home: 'Accueil',
  // Checkout
  ShippingAddress: 'Adresse de livraison',
  Checkout: 'Check-out',
  ThankYouForYourOrder: 'Merci pour votre commande',
  YourOrderNumber: 'Votre numéro de commande',
  OrderConfirmation: 'Confirmation de commande',
  Back: 'Retour',
  PaymentMethod: 'Mode de paiement',
  OrderSummary: 'Résumé de la commande',
  Total: 'Total',
  PaymentDetails: 'Détails de paiement',
  OrderDate: 'Date de commande',
  Name: 'Nom',
  Quantity: 'Quantité',
  Price: 'Prix',
  Client: 'Client',
  company: `l'entreprise`,
  name: 'nom',
  email: 'email',
  // Category
  Category: 'Catégorie',
  SportItems: 'Articles de sport',
  CareItems: 'Articles de soins',
  Hardware: 'Matériel',
  NoItemsFound: 'Aucun élément trouvé',
  // Rest
  Address: 'Adresse',
  AdditionalInformation: 'Informations complémentaires',
  MyWishlist: 'Ma liste de souhaits',
  Account: 'Compte',
  Cart: 'Chariot',
  Language: 'La langue',
  search: 'Chercher',
  Join: 'Joindre',
  Create: 'Créer',
  Street: 'Rue',
  Number: 'Numéro',
  ZipCode: 'Code postal',
  City: 'Ville',
  Country: 'Pays',
  Alias: 'Alias',
  FirstName: 'Prénom',
  LastName: 'Nom de famille',
  Email: 'Email',
  Role: 'Rôle',
  AddressLine1: 'rue',
  AddressLine2: 'Numéro',
  StateProvinceRegion: 'État / Province / Région',
  ZipPostalCode: 'Code postal',
  UseThisAddressForPaymentDetails: 'Utiliser cette adresse pour les détails de paiement',
  NameOnCard: 'Nom sur la carte',
  CardNumber: 'Numéro de carte',
  ExpiryDate: 'Date d expiration',
  RememberCreditCardDetailsForNextTime: 'Se souvenir des détails de la carte de crédit pour la prochaine fois',
  LastThreeDigitsOnSignatureStrip: 'Les trois derniers chiffres sur la bande de signature',
  ShareWithOthers: 'Partager avec les autres',
  CopyThisLinkThenYouCanPasteAndShareIt: 'Copiez ce lien, vous pouvez le coller et le partager',
  Copy: 'Copier',
  ItIsCurrentlyNot: `Il n'est actuellement pas possible de modifier votre commande une fois qu'elle a été passée.`,
  // Account
  UserInformation: 'Informations sur l utilisateur',
  AddressInformation: 'Informations sur l adresse',
  Options: 'Options',
  Logout: 'déconnexion',
  DeleteAccount: 'Supprimer le compte',
  LeaveCompany: 'Quitter la compagnie',
  DeleteCompany: 'Supprimer la compagnie',
  Save: 'sauvegarder',
  AdminPanel: 'Panneau d administration',
  ChangeRole: 'Changer de rôle',
  ChangeTheRoleOfAnEmployeeOrAllowAnAccountToJoinTheCompany: 'Changez le rôle d un employé ou autorisez un compte à rejoindre la société',
  CompanyOptions: 'Options de la société',
  EmployeePanel: 'Panneau des employés',
  Orders: 'Ordres',
  UseVATNumberToGetCompanyInformation: 'Utilisez le numéro de TVA pour obtenir des informations sur la société',
  CreateCompany: 'Créer une entreprise',
  Login: 'S identifier',
  NoAccount: 'Pas de compte',
  Register: 'registre',
  goToLogin: 'Aller à la connexion',
  LoginRequired: 'Connexion requise',
  YouNeedToLoginToAccessThisPage: 'Vous devez vous connecter pour accéder à cette page',
  GoToLogin: 'Aller à la connexion',
  AccountConfirmation: 'Êtes-vous sûr de vouloir supprimer votre compte ? Supprimer toutes les données utilisateur et se déconnecter ?',
  AdminDelete: `Un compte administrateur ne peut être supprimé que s'il y a un autre administrateur dans l'entreprise !`,
  FailedDelete: `Échec de la suppression du compte ; nommer d'abord un nouvel administrateur`,
  FailedDelete2: 'Échec de la suppression du compte',
  DeleteSuccess: 'Compte supprimé avec succès',
  LEAVECOMPANY: 'QUITTER LA SOCIÉTÉ',
  FailedLeave: `n'a pas quitté l'entreprise ; nommer d'abord un nouvel administrateur`,
  FailedLeave2: `Impossible de quitter l'entreprise`,
  SuccessLeave: 'Vous avez quitté l entreprise avec succès',
  DeleteCompConfirmation: 'Voulez-vous vraiment supprimer votre entreprise ?',
  DELETECOMPANY: 'SUPPRIMER LA SOCIÉTÉ',
  FailedDeleteComp: 'Impossible de supprimer la société',
  SuccessDeleteComp: 'Société supprimée avec succès',
  CanDelete: 'Vous pouvez supprimer votre compte',
  CanLeave: 'Vous pouvez quitter votre entreprise',
  CanDeleteComp: 'Vous pouvez supprimer votre entreprise',
  UserInfSaved: 'Informations utilisateur enregistrées',
  success: 'Succès',
  AddressInfoSaved: 'Informations sur l adresse enregistrées',
  RoleOrStatus: `Le rôle ou le statut des employés au sein de l'entreprise`,
  RoleConfirmation: 'Êtes-vous sûr de vouloir changer votre propre rôle ?',
  CHANGEROLE: 'CHANGER DE RÔLE',
  RoleChangeFailed: `Échec de la modification de votre propre rôle, assurez-vous qu'il existe au moins un autre administrateur !`,
  FirstEnterEmail: `Entrez d'abord un e-mail du compte que vous souhaitez promouvoir`,
  SelectMergeStrat: 'Sélectionnez une stratégie de fusion',
  FailedCreateComp: 'Impossible de créer la société',
  SuccesfullyRequested: 'Demande envoyée avec succès',
  FailedJoinComp: 'Impossible de rejoindre la société',
  JOINCOMPANY: 'REJOINDRE LA SOCIÉTÉ',
  PutOnWaitingList: `Vous avez été mis sur une liste d'attente pour rejoindre une entreprise. L'administrateur de cette société devra d'abord vous valider.`,
  CHANGEREQUEST: 'CHANGER LA DEMANDE',
  CompanyDontExist: `La société n'existe pas ; assurez-vous de le formater comme BE0123456789`,
  VATNumber: 'Numéro de TVA',
  CompanyName: 'Nom de la compagnie',
  FailedToRegister: `Échec de l'inscription ; Entrer un email valide`,
  FailedToLogin: 'Échec de la connexion ; email ou mot de passe erroné',
  FailedToLogin2: 'Échec de la connexion ; remplir les champs obligatoires',
  SignIn: 'Se connecter',
  Password: 'Mot de passe',
  FailedToRegister2: `Échec de l'inscription ; l'utilisateur existe déjà`,
  FailedToRegister3: `Échec de l'inscription`,
  FailedToRegister4: `Échec de l'inscription ; remplir tous les champs de saisie`,
  Username: 'Nom d utilisateur',
  // Banner
  Interested: 'Intéressé ?',
  ShipWithConfidence: `Expédiez en toute confiance : des solutions d'expédition sans tracas`,
  BannerDescription: `Livrez en toute confiance ! Obtenez une expédition sans tracas avec nos services de confiance. Choisissez parmi une variété d'options pour expédier au niveau national ou international, et soyez assuré que vos colis arriveront en toute sécurité et à temps.`,
  // Cart
  MyShoppingCart: 'Mon panier',
  NoItemsInCart: 'Aucun article dans le panier',
  ThisIsSomeRandomInfo: 'Ceci est des informations aléatoires',
  Subtotal: 'Total partiel',
  TAX: 'TAXE',
  Shipping: 'Expédition',
  NetPrice: 'Prix net',
  OrderNow: 'Commander maintenant',
  ClearCart: 'Vider le panier',
  // Categories
  FailedToLoadCategories: 'Impossible de charger les catégories',
  NoCategoriesFound: 'Aucune catégorie trouvée',
  // Contact
  DoYouHaveAQuestionOrDoYouWantMoreInformation: 'Avez-vous une question ou souhaitez-vous plus d informations ?',
  IamNotACustomerYet: 'Je ne suis pas encore client',
  WouldYouLikeMoreInformationAboutOurOfferingsOrServices: 'Souhaitez-vous plus d informations sur nos offres ou services ?',
  FillOutTheContactFormAndWeWillGetBackToYou: 'Remplissez le formulaire de contact et nous vous répondrons dans les plus brefs délais.',
  FillOutAContactForm: 'Remplissez un formulaire de contact',
  IAmACustomer: 'Je suis un client',
  DoYouHaveASpecificQuestionOrSuggestions: 'Avez-vous une question spécifique ou des suggestions ?',
  LetUsKnow: 'Faites-le nous savoir et nous répondrons à votre question dans les plus brefs délais.',
  PerhapsYourQuestionIsAlreadyKnown: 'Peut-être que votre question est déjà connue',
  PleaseReferToOur: 'Veuillez vous référer à notre',
  FrequentlyAskedQuestions: 'Questions fréquemment posées',
  QuestionsAnswers: 'Questions / Réponses',
  WhyCantICompleteMyOrder: 'Pourquoi ne puis-je pas compléter ma commande ?',
  CheckTheFollowingItems: 'Vérifiez les éléments suivants :',
  AreYouLoggedInRegisterFirst: `1. Êtes-vous connecté ? Si vous n'avez pas encore de login, vous devez d'abord vous inscrire.`,
  HaveYouAlreadyConfirmed: `2. Avez-vous déjà confirmé la date de livraison proposée ou choisi une autre date ? Vous pouvez voir cette date en haut de la page d'aperçu de votre panier.`,
  AreThereProductsInYourShoppingBasket: `3. Y a-t-il des produits dans votre panier pour lesquels il est indiqué qu'il n'y a pas ou pas assez de stock ? Ajustez les quantités de votre commande si nécessaire.`,
  HowCanIChangeMyOrder: 'Comment puis-je modifier ma commande ?',
  HowAndWhenDoIPay: 'Comment et quand dois-je payer ?',
  WithAPositiveCreditScore: 'Avec une cote de crédit positive, vous payez par facture. Le délai de paiement et la limite de crédit sont également déterminés en fonction de ce score. Sinon, vous payez à notre livreur lors de la livraison via Bancontact, Visa ou Mastercard.',
  ContactUs: 'Contactez-nous',
  FillUpTheForm: 'Remplissez le formulaire et notre équipe vous répondra dans les 24 heures.',
  Submit: 'Soumettre',
  Information: 'Information',
  Call: 'Appel + 32 485 88 77 11',
  Mail: 'Écrivez-nous à qwertic@qwict.com',
  Phone: 'Téléphone',
  TypeUrMessageHere: 'Tapez votre message ici',
  // Footer
  FooterCap: `Bienvenue sur notre site d'expédition! Nous sommes une équipe de professionnels de la logistique dédiée à fournir des solutions d'expédition efficaces, fiables et abordables pour les particuliers et les entreprises. Avec un réseau de transporteurs de confiance et une technologie de pointe, nous faisons de l'expédition une expérience transparente et sans tracas. Laissez-nous vous aider à étendre votre portée grâce à nos options de transport aérien, maritime et terrestre, ainsi qu'à nos solutions de dédouanement et d'entreposage. Contactez-nous dès aujourd'hui pour en savoir plus sur la façon dont nous pouvons vous aider avec vos besoins d'expédition.`,
  AboutUs: 'À propos de nous',
  information: 'information',
  OrderTracking: 'Suivi de commande',
  PrivacyPolicy: 'Confidentialité & Politique',
  TermsConditions: 'Conditions & Conditions',
  myaccount: 'mon compte',
  MyCart: 'Mon panier',
  MyAccount: 'Mon compte',
  // Home
  WelcomeToDelaware: `Bienvenue chez Delaware Shipping, votre partenaire de confiance pour tous vos besoins d'expédition !`,
  DelawareDescription: `Chez Delaware Shipping, nous comprenons que l'expédition peut être une tâche ardue. Que vous soyez une petite entreprise cherchant à envoyer des produits à des clients à travers le pays ou un particulier envoyant un cadeau à un être cher, nous sommes là pour vous aider. Notre mission est de fournir à nos clients des services d'expédition de la plus haute qualité à des prix compétitifs, tout en assurant une livraison rapide et un excellent service client.`,
  mainimagedescription: `description de l'image principale`,
  continuereading: 'continuer la lecture',
  FailedLoadCategories: 'Impossible de charger les catégories...',
  // Notifications
  Notifications: 'Notifications',
  NoNotifications: 'Aucune notification',
  OrderId: 'Order ID',
  Date: 'Date',
  Text: 'Texte',
  Status: 'Status',
  audience: 'audience',
  Audience: 'Audience',
  subject: 'sujet',
  Subject: 'Sujet',
  date: 'date',
  readBy: 'lu par',
  ReadBy: 'Lu par',
  action: 'action',
  Action: 'Action',
  General: 'Général',
  FindAllNotificationsHere: 'Vous y trouverez toutes vos notifications. Vous pouvez également les marquer comme lus ou les archiver.',
  AllInfoAboutOrders: 'Toutes les informations concernant vos commandes se trouvent ici.',
  ReadAndArchived: 'Lu et archivé',
  Read: 'Lu',
  FindReadNotificationsHere: 'Vous pouvez trouver toutes vos notifications lues ici.',
  Archived: 'Archivé',
  FindArchivedNotificationsHere: 'Vous pouvez trouver toutes vos notifications archivées ici.',
  // Order
  DeliveryAddress: 'Adresse de livraison',
  Transportation: 'Transport',
  packaging: 'emballage',
  PackagingType: 'Type d\'emballage',
  TransportService: 'Service de transport',
  VerificationCode: 'Code de vérification',
  TrackYourPackage : 'Suivez votre colis',
  Details: 'Détails',
  NoOrders: 'Aucune commande',
  ClientName: 'Nom du client',
  SuccesfullySavedAddress: `Informations d'adresse enregistrées avec succès`,
  SuccesfullyReceivedAddress: `Informations d'adresse reçues avec succès`,
  SuccesfullyPlacedOrder: 'Commande passée avec succès',
  PlaceOrder: 'Passer la commande',
  OrderPlaceConfirmation: 'Êtes-vous sûr de vouloir passer cette commande ?',
  PLACEORDER: 'PASSER LA COMMANDE',
  FailedToPlaceOrder: 'Impossible de passer la commande',
  PackagingMethod: 'Méthode d\'emballage',
  SuccesfullySavedPaymentOption: 'Option de paiement enregistrée avec succès',
  CardType: 'Type de carte',
  CardHolder: 'Titulaire de la carte',
  MrMs: 'Monsieur / Madame',
  // Orders
  OrderDetails: 'Détails de la commande',
  geplaatst: 'mis',
  inVerwerking: 'en cours de traitement',
  // Product
  ProductTitle: 'Titre du produit',
  Currently: 'Actuellement',
  InStock: 'En stock',
  AddToCart: 'Ajouter au panier',
  AddToWishlist: 'Ajouter à la liste de souhaits',
  HowManyAddToCart: 'Combien voulez-vous ajouter au panier ?',
  AddedToCart: 'Ajouté au panier!',
  NotEnoughStock: 'Pas assez de stock',
  AvailableCategories: 'Catégories disponibles',
  ShareThisProduct: 'Partager ce produit',
  CopyThisLinkPasteShare: 'Copiez ce lien. Ensuite, vous pouvez le coller et le partager.',
  // Track
  TrackOrder: 'Suivre la commande',
};
export default frTranslations;