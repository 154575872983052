const enTranslations = {
  // Navbar
  Categories: 'Categories',
  Products: 'Products',
  Track: 'Track',
  NeedAssistance: 'Information',
  // Not Found
  ThePageYourLookingForDoesntExist: 'The page your looking for doesn t exist',
  BackHome: 'Back Home',
  Home: 'Home',
  // Checkout
  ShippingAddress: 'Shipping Address',
  Checkout: 'Checkout',
  ThankYouForYourOrder: 'Thank you for your order!',
  YourOrderNumber: 'Your order number',
  OrderConfirmation: 'We have emailed your order confirmation, and will send you an update when your order has shipped.',
  Back: 'Back',
  PaymentMethod: 'Payment Method',
  OrderSummary: 'Order Summary',
  Total: 'Total',
  PaymentDetails: 'Payment Details',
  OrderDate: 'Order Date',
  Name: 'Name',
  Quantity: 'Quantity',
  Price: 'Price',
  Client: 'Client',
  company: 'company',
  name: 'name',
  email: 'email',
  // Category
  Category: 'Category',
  SportItems: 'Sport Items',
  CareItems: 'Care Items',
  Hardware: 'Hardware',
  NoItemsFound: 'No items found',
  // Rest
  Address: 'Address',
  AdditionalInformation: 'Additional Information',
  MyWishlist: 'My Wishlist',
  Account: 'Account',
  Cart: 'Cart',
  Language: 'Language',
  search: 'search',
  Join: 'Join',
  Create: 'Create',
  Street: 'Street',
  Number: 'Number',
  ZipCode: 'Zip Code',
  City: 'City',
  Country: 'Country',
  Alias: 'Alias',
  FirstName: 'First Name',
  LastName: 'Last Name',
  Email: 'Email',
  Role: 'Role',
  AddressLine1: 'Street',
  AddressLine2: 'StreetNumber',
  StateProvinceRegion: 'State/Province/Region',
  ZipPostalCode: 'Zip/Postal Code',
  UseThisAddressForPaymentDetails: 'Use this address for payment details',
  NameOnCard: 'Name on Card',
  CardNumber: 'Card Number',
  ExpiryDate: 'Expiry Date',
  RememberCreditCardDetailsForNextTime: 'Remember credit card details for next time',
  LastThreeDigitsOnSignatureStrip: 'Last three digits on signature strip',
  ShareWithOthers: 'Share with others',
  CopyThisLinkThenYouCanPasteAndShareIt: 'Copy this link, then you can paste and share it',
  Copy: 'Copy',
  ItIsCurrentlyNot: 'It is currently not possible to change your order after it has been placed.',
  // Account
  UserInformation: 'User Information',
  AddressInformation: 'Address Information',
  Options: 'Options',
  Logout: 'Logout',
  DeleteAccount: 'Delete Account',
  LeaveCompany: 'Leave Company',
  DeleteCompany: 'Delete Company',
  Save: 'Save',
  AdminPanel: 'Admin Panel',
  ChangeRole: 'Change Role',
  ChangeTheRoleOfAnEmployeeOrAllowAnAccountToJoinTheCompany: 'Change the role of an employee. Or allow an account to join the company.',
  CompanyOptions: 'Company Options',
  EmployeePanel: 'Employee Panel',
  Orders: 'Orders',
  UseVATNumberToGetCompanyInformation: 'Use VAT number to get company information?',
  CreateCompany: 'Create Company',
  Login: 'Login',
  NoAccount: 'No account?',
  Register: 'Register',
  goToLogin: 'Go to login',
  LoginRequired: 'Login Required',
  YouNeedToLoginToAccessThisPage: 'You need to login to access this page',
  GoToLogin: 'Go to login',
  AccountConfirmation: 'Are you sure you want to delete your account? Delete all user data and logout?',
  AdminDelete: 'An administrator account can only be deleted if there is an other admin in the company!',
  FailedDelete: 'Failed to delete account; appoint a new admin first',
  FailedDelete2: 'Failed to delete account',
  DeleteSuccess: 'Account deleted successfully',
  LEAVECOMPANY: 'LEAVE COMPANY',
  FailedLeave: 'Failed to leave company; appoint a new admin first',
  FailedLeave2: 'Failed to leave company',
  SuccessLeave: 'Successfully left company',
  DeleteCompConfirmation: 'Are you sure you want to delete your company?',
  DELETECOMPANY: 'DELETE COMPANY',
  FailedDeleteComp: 'Failed to delete company',
  SuccessDeleteComp: 'Successfully deleted company',
  CanDelete: 'You can delete your account',
  CanLeave: 'You can leave your company',
  CanDeleteComp: 'You can delete your company',
  UserInfSaved: 'User information saved',
  success: 'success',
  AddressInfoSaved: 'Address information saved',
  RoleOrStatus: 'The role or status of employees within the company',
  RoleConfirmation: 'Are you sure you want to change your own role?',
  CHANGEROLE: 'CHANGE ROLE',
  RoleChangeFailed: 'Failed to change your own role, make sure that there is at least one other admin!',
  FirstEnterEmail: 'First enter an email of the account you want to promote',
  SelectMergeStrat: 'Select merge strategy',
  FailedCreateComp: 'Failed to create company',
  SuccesfullyRequested: 'Successfully requested to join company',
  FailedJoinComp: 'Failed to join company',
  JOINCOMPANY: 'JOIN COMPANY',
  PutOnWaitingList: 'You were put on a waiting list to join a company. The administrator of that company will first have to validate you.',
  CHANGEREQUEST: 'CHANGE REQUEST',
  CompanyDontExist: 'Company does not exist; make sure to format it like BE0123456789',
  VATNumber: 'VAT Number',
  CompanyName: 'Company Name',
  FailedToRegister: 'Failed to register; enter a valid email',
  FailedToLogin: 'Failed to login; email or password wrong',
  FailedToLogin2: 'Failed to login; fill in required fields',
  SignIn: 'Sign In',
  Password: 'Password',
  FailedToRegister2: 'Failed to register; user already exists',
  FailedToRegister3: 'Failed to register',
  FailedToRegister4: 'Failed to register; fill in all input fields',
  Username: 'Username',
  // Banner
  Interested: 'Interested?',
  ShipWithConfidence: 'Ship with Confidence: Hassle-Free Shipping Solutions',
  BannerDescription: 'Deliver with confidence! Get hassle-free shipping with our trusted services. Choose from a variety of options to ship domestically or internationally, and rest assured that your packages will arrive safely and on time.',
  // Cart
  MyShoppingCart: 'My Shopping Cart',
  NoItemsInCart: 'No items in cart',
  ThisIsSomeRandomInfo: 'This is some random info',
  Subtotal: 'Subtotal: ',
  TAX: 'TAX: ',
  Shipping: 'Shipping: ',
  NetPrice: 'Net Price: ',
  OrderNow: 'Order Now!',
  ClearCart: 'Clear Cart',
  // Categories
  FailedToLoadCategories: 'Failed to load categories',
  NoCategoriesFound: 'No categories found',
  // Contact
  DoYouHaveAQuestionOrDoYouWantMoreInformation: 'Do you have a question or do you want more information?',
  IamNotACustomerYet: 'I am not a customer yet',
  WouldYouLikeMoreInformationAboutOurOfferingsOrServices: 'Would you like more information about our offerings or services?',
  FillOutTheContactFormAndWeWillGetBackToYou: 'Fill out the contact form and we will get back to you.',
  FillOutAContactForm: 'Fill out a contact form!',
  IAmACustomer: 'I am a customer',
  DoYouHaveASpecificQuestionOrSuggestions: 'Do you have a specific question or suggestion?',
  LetUsKnow: 'Let us know and we will answer your question as soon as possible.',
  PerhapsYourQuestionIsAlreadyKnown: 'Perhaps your question is already known',
  PleaseReferToOur: 'Please refer to our',
  FrequentlyAskedQuestions: 'Frequently Asked Questions',
  QuestionsAnswers: 'Questions & Answers',
  WhyCantICompleteMyOrder: 'Why can t I complete my order?',
  CheckTheFollowingItems: 'Check the following items: ',
  AreYouLoggedInRegisterFirst: '1. Are you logged in? If you don t have a login yet, you need to register first.',
  HaveYouAlreadyConfirmed: '2. Have you already confirmed the proposed delivery date or chosen another date? You can see this date at the top of the overview page of your shopping cart.',
  AreThereProductsInYourShoppingBasket: '3. Are there products in your shopping basket for which it is indicated that there is no or insufficient stock? Adjust your order quantities if necessary.',
  HowCanIChangeMyOrder: 'How can I change my order?',
  HowAndWhenDoIPay: 'How and when do I pay?',
  WithAPositiveCreditScore: 'With a positive credit score, you pay by invoice. The payment term and credit limit are also determined based on this score. Otherwise you pay to our delivery person upon delivery via Bancontact, Visa or Mastercard.',
  ContactUs: 'Contact Us',
  FillUpTheForm: 'Fill up the form and our team will get back to you within 24 hours.',
  Submit: 'Submit',
  Information: 'Information',
  Call: 'Call + 32 485 88 77 11',
  Mail: 'Mail us at qwertic@qwict.com',
  Phone: 'Phone',
  TypeUrMessageHere: 'Type your message here',
  // Footer
  FooterCap: `Welcome to our shipping website! We're a team of logistics professionals dedicated to providing efficient, reliable, and affordable shipping solutions for individuals and businesses. With a network of trusted carriers and cutting-edge technology, we make shipping a seamless and hassle-free experience. Let us help you expand your reach with our air, sea, and ground transportation options, as well as customs clearance and warehousing solutions. Contact us today to learn more about how we can assist you with your shipping needs.`,
  AboutUs: 'About Us',
  information: 'information',
  OrderTracking: 'Order Tracking',
  PrivacyPolicy: 'Privacy & Policy',
  TermsConditions: 'Terms & Conditions',
  myaccount: 'my account',
  MyCart: 'My Cart',
  MyAccount: 'My Account',
  // Home
  WelcomeToDelaware: 'Welcome to Delaware Shipping, your trusted partner for all your shipping needs!',
  DelawareDescription: 'At Delaware Shipping, we understand that shipping can be a daunting task. Whether you are a small business looking to send products to customers across the country or an individual sending a gift to a loved one, we are here to help. Our mission is to provide our clients with the highest quality shipping services at competitive prices, while ensuring timely delivery and excellent customer service.',
  mainimagedescription: 'main image description',
  continuereading: 'continue reading...',
  FailedLoadCategories: 'Failed to load categories',
  // Notifications
  Notifications: 'Notifications',
  NoNotifications: 'No notifications',
  OrderId: 'Order Id',
  Date: 'Date',
  Text: 'Text',
  Status: 'Status',
  audience: 'audience',
  Audience: 'Audience',
  subject: 'subject',
  Subject: 'Subject',
  date: 'date',
  readBy: 'read by',
  ReadBy: 'Read by',
  action: 'action',
  Action: 'Action',
  General: 'General',
  FindAllNotificationsHere: 'You will find all your notifications here. You can also mark them as read or archive them.',
  AllInfoAboutOrders: 'All information about your orders can be found here.',
  ReadAndArchived: 'Read and Archived',
  Read: 'Read',
  FindReadNotificationsHere: 'You can find all your read notifications here.',
  Archived: 'Archived',
  FindArchivedNotificationsHere: 'You can find all your archived notifications here.',
  // Order
  DeliveryAddress: 'Delivery Address',
  Transportation: 'Transportation',
  packaging: 'packaging',
  PackagingType: 'Packaging Type',
  TransportService: 'Transport Service',
  VerificationCode: 'Verification Code',
  TrackYourPackage: 'Track your package',
  Details: 'Details',
  NoOrders: 'No orders',
  ClientName: 'Client Name',
  SuccesfullySavedAddress: 'Succesfully saved address information',
  SuccesfullyReceivedAddress: 'Succesfully received address information',
  SuccesfullyPlacedOrder: 'Succesfully placed your order!',
  PlaceOrder: 'Place Order',
  OrderPlaceConfirmation: 'Are you sure you want to place this order?',
  PLACEORDER: 'PLACE ORDER',
  FailedToPlaceOrder: 'Failed to place order',
  PackagingMethod: 'Packaging Method',
  SuccesfullySavedPaymentOption: 'Succesfully saved payment option',
  CardType: 'Card Type',
  CardHolder: 'Card Holder',
  MrMs: 'Mr/Ms',
  // Orders
  OrderDetails: 'Order Details',
  geplaatst: 'placed',
  inVerwerking: 'in progress',
  // Product
  ProductTitle: 'Product Title',
  Currently: 'Currently',
  InStock: 'In Stock',
  AddToCart: 'Add to Cart',
  AddToWishlist: 'Add to Wishlist',
  HowManyAddToCart: 'How many do you wish to add to your cart ?',
  AddedToCart: 'Added to cart!',
  NotEnoughStock: 'There is not enough stock!',
  AvailableCategories: 'Available Categories',
  ShareThisProduct: 'Share this product',
  CopyThisLinkPasteShare: 'Copy this link. Then you can paste and share it.',
  // Track
  TrackOrder: 'Track Order',
};
export default enTranslations;